<template>
  <v-row class="pt-10">
    <v-col cols="12">
      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-header>{{ title }}</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-col class="pr-8" cols="12">
                <div>
                  <div class="float-right">
                    <v-btn @click="copy(code)" class="ml-n10 mt-4" icon>
                      <v-icon v-text="'mdi-content-copy'"></v-icon>
                    </v-btn>
                  </div>
                  <highlight language="swift">{{ code }}</highlight>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-row justify="center">
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn color="primary" v-bind="attrs" v-on="on" text>
                        Documentation
                        <v-icon right>mdi-menu-down</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item
                        v-for="(link, lindex) in linkList"
                        :key="lindex"
                        link
                        :href="link.link"
                        target="_blank"
                      >
                        <v-list-item-title>{{ link.title }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-row>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-divider class="my-4"></v-divider>
    </v-col>
  </v-row>
</template>
<script>
import hljs from "highlight.js";
import Highlight from "vue-highlight-component";
hljs.registerLanguage("swift", require("highlight.js/lib/languages/swift"));

export default {
  components: {
    Highlight,
  },
  props: {
    title: {
      type: String,
      default: "Swift Example",
    },
    code: {
      type: String,
      default: `TestAppio.user.identify(
                   userId: "U123456", //required
           name: "Jane Doe", //optional
          email: "jane.doe@example.com", //optional
          traits: [  //optional
          "age": "30",
          "plan": "premium",
          "ab_test_variant": "variation_A"
          ]
     )`,
    },
    linkList: {
      type: Array,
      default() {
        return [
          {
            title: "Swift",
            link: "https://github.com/testappio/ios-sd",
          },
          {
            title: "Flutter",
            link: "https://github.com/testappio/flutter-sdk",
          },
        ];
      },
    },
  },
  data() {
    return {};
  },
  methods: {
    switchHighlightTheme(isDark) {
      const themeStyleId = "highlight-theme-style";
      let linkElement = document.getElementById(themeStyleId);

      // If the link element doesn't exist, create it
      if (!linkElement) {
        linkElement = document.createElement("link");
        linkElement.id = themeStyleId;
        linkElement.rel = "stylesheet";
        document.head.appendChild(linkElement);
      }

      // Update the href attribute based on the theme
      if (isDark) {
        linkElement.href = "/css/highlight.js/styles/atom-one-dark.css";
      } else {
        linkElement.href = "/css/highlight.js/styles/github.css";
      }
    },
  },

  watch: {
    "$vuetify.theme.dark"(newVal) {
      this.switchHighlightTheme(newVal);
    },
  },

  mounted() {
    this.switchHighlightTheme(this.$vuetify.theme.dark);
  },
};
</script>
