var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "pt-10"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-expansion-panels', [_c('v-expansion-panel', [_c('v-expansion-panel-header', [_vm._v(_vm._s(_vm.title))]), _c('v-expansion-panel-content', [_c('v-row', [_c('v-col', {
    staticClass: "pr-8",
    attrs: {
      "cols": "12"
    }
  }, [_c('div', [_c('div', {
    staticClass: "float-right"
  }, [_c('v-btn', {
    staticClass: "ml-n10 mt-4",
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.copy(_vm.code);
      }
    }
  }, [_c('v-icon', {
    domProps: {
      "textContent": _vm._s('mdi-content-copy')
    }
  })], 1)], 1), _c('highlight', {
    attrs: {
      "language": "swift"
    }
  }, [_vm._v(_vm._s(_vm.code))])], 1)])], 1), _c('v-row', [_c('v-col', [_c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-menu', {
    attrs: {
      "offset-y": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "color": "primary",
            "text": ""
          }
        }, 'v-btn', attrs, false), on), [_vm._v(" Documentation "), _c('v-icon', {
          attrs: {
            "right": ""
          }
        }, [_vm._v("mdi-menu-down")])], 1)];
      }
    }])
  }, [_c('v-list', _vm._l(_vm.linkList, function (link, lindex) {
    return _c('v-list-item', {
      key: lindex,
      attrs: {
        "link": "",
        "href": link.link,
        "target": "_blank"
      }
    }, [_c('v-list-item-title', [_vm._v(_vm._s(link.title))])], 1);
  }), 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1), _c('v-divider', {
    staticClass: "my-4"
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }